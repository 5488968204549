import {CustomApiError, CustomErrorType} from "./CustomApiError";
import {CensoredSecret, PartialSecret, UncensoredSecret} from "../interfaces/Secret";
import {BaseApi} from "@matillion/octo-react-util";

class Api extends BaseApi {
    override async handleBadResponse(response: Response): Promise<never> {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            let json = await response.json()
            if(json.hasOwnProperty("message") && json.hasOwnProperty("errorType") && json.hasOwnProperty("data")) {
                let message = json.message
                let errorType = CustomErrorType[json.errorType as keyof typeof CustomErrorType]
                let data = json.data
                throw new CustomApiError(message, errorType, data)
            }else
                throw Error("Error with request ["+response.status+"]: " + JSON.stringify(json))
        } else
            throw Error("Error with request ["+response.status+"]: " + await response.text())
    }

    async listSecrets(tags?: string[]): Promise<CensoredSecret[]> {
        return this.get(`/api/secrets${tags?.length ? `?tags=${tags.join(",")}` : ""}`)
    }

    async getCensoredSecret(name: string): Promise<CensoredSecret> {
        return this.get(`/api/secrets/${name}?censored=true`)
    }

    async updateSecret(name: string, partialSecret: PartialSecret) {
        return this.post(`/api/secrets/${name}`, partialSecret)
    }

    async deleteSecret(name: string) {
        await this.delete(`/api/secrets/${name}`)
    }

    async createSecret(name: string, uncensoredSecret: UncensoredSecret) {
        await this.put(`/api/secrets/${name}`, uncensoredSecret)
    }
}

export default Api