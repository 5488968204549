import SecretElement from "./SecretElement";
import {AutoComplete, AutoCompleteItemId, Button, Input, Typography} from "@matillion/component-library";
import {useEffect, useState} from "react";
import {ValueType} from "../interfaces/Secret";
import TagSet from "./TagSet";
import {useAppProps} from "@matillion/octo-react-util";
import SpecialTag from "../interfaces/SpecialTag";

interface SecretInputFieldsProps {
    newName: string
    setNewName: (newName: string) => void
    newDescription: string
    setNewDescription: (newDescription: string) => void
    newTags: string[]
    setNewTags: (newTags: string[]) => void
    newValue: string
    setNewValue: (newValue: string) => void
    newValueType: ValueType
    setNewValueType: (newValueType: ValueType) => void
}

const SecretInputFields = ({
                               newName,
                               setNewName,
                               newDescription,
                               setNewDescription,
                               newTags,
                               setNewTags,
                               newValue,
                               setNewValue,
                               setNewValueType,
                           }: SecretInputFieldsProps) => {
    const [acValueType, setAcValueType] = useState<AutoCompleteItemId>({id: 0, name: 'RAW'})
    const [newTagName, setNewTagName] = useState<string>('')
    const {secretService: {specialTags}} = useAppProps()

    useEffect(() => {
        setNewValueType(ValueType[acValueType.name as keyof typeof ValueType])
    }, [setNewValueType, acValueType])

    const autoCompleteItems = Object.keys(ValueType)
        .map((key) => +key)
        .filter((key) => !isNaN(key))
        .map((key) => ({id: key, name: ValueType[key]}))

    function addTag() {
        if(newTagName !== '' && !newTags.includes(newTagName)) {
            setNewTags([...newTags, newTagName])
        }
        setNewTagName('')
    }

    function setNewNameWithCheck(newName: string) {
        newName = newName.replaceAll(/\s/g, "_").replaceAll(/\W/g, "")
        setNewName(newName)
    }

    return (
        <>
            <SecretElement name={"Name"}>
                <Input placeholder="Example Secret" value={newName} onChange={(e: any) => setNewNameWithCheck(e.target.value)}/>
            </SecretElement>
            <SecretElement name={"Description"}>
                <Input placeholder="This secret is for..." value={newDescription}
                       onChange={(e: any) => setNewDescription(e.target.value)}/>
            </SecretElement>
            <SecretElement name={"Tags"}>
                <Typography>Secrets that show up green and secrets that are suggested to you have a special purpose.</Typography>
                <TagSet tags={newTags} editing={{setTags: setNewTags}}/>
                <Typography style={{color: "red"}}>Click on a tag to remove it form the list.</Typography>
                <AutoComplete
                    availableItems={specialTags.map((tag: SpecialTag) => ({id: tag.name, name: tag.name}))}
                    placeholder={"Example Tag"}
                    value={{id: newTagName, name: newTagName}}
                    onChange={({target: {value}}) => {if (value) setNewTagName(value?.name)}}
                    allowFreetext={true}
                />
                <Button text={"Add Tag"} size={"sm"} onClick={() => addTag()}/>
            </SecretElement>
            <SecretElement name={"Value"}>
                <Input placeholder="S£cR£t V@!Ue" value={newValue} onChange={(e: any) => setNewValue(e.target.value)}/>
                <Typography weight="bold">Type of secret: </Typography>
                <Typography format="bcs">
                    RAW: raw value of secret. <br/>
                    REF: reference to another secret. For example arn of secret in AWS Secret Manager.
                </Typography>
                <AutoComplete
                    onChange={({target: {value}}) => {
                        if (value) setAcValueType(value)
                    }}
                    availableItems={autoCompleteItems}
                    allowFreetext={false}
                    value={acValueType}
                />
            </SecretElement>
        </>
    )
}

export default SecretInputFields