export interface SecretValue {
    value: string
    type: ValueType
}

export enum ValueType {
    RAW,
    REF,
}

export interface UncensoredSecret {
    name: string,
    value: SecretValue,
    description: string,
    tags: string[],
}

export interface CensoredSecret {
    name: string,
    description: string,
    tags: string[],
}

export interface PartialSecret {
    name?: string,
    value?: SecretValue,
    description?: string,
    tags?: string[],
}