import {useEffect, useState} from "react";
import {ValueType} from "../interfaces/Secret";
import useSecret from "./UseSecret";
import {Button, LoadingSpinner, Typography} from "@matillion/component-library";
import {Divider} from "@mui/material";
import SecretInputFields from "./SecretInputFields";
import useErrorHandledAsync from "../common/UseErrorHandledAsync";
import {PageState, PageType} from "./SecretManagerInterface";
import {useApi} from "@matillion/octo-react-util";
import Api from "../api/Api";

const SecretEditor = ({page, setPage}: PageState) => {
    if(page.type !== PageType.EDITOR) throw Error()
    const api = useApi<Api>()
    const {secret, loading} = useSecret(page.secretName)
    const [newName, setNewName] = useState('')
    const [newDescription, setNewDescription] = useState('')
    const [newTags, setNewTags] = useState<string[]>([])
    const [newValue, setNewValue] = useState('')
    const [newValueType, setNewValueType] = useState<ValueType>(ValueType.RAW)


    useEffect(() => {
        if(secret) {
            setNewName(secret.name)
            setNewDescription(secret.description)
            setNewTags(secret.tags)
        }
    }, [secret])

    const {errorHandledAsync: errorHandledUpdate, loading: updating} = useErrorHandledAsync(async () => {
        if(page.secretName)
            await api.updateSecret(page.secretName, {
                name: newName,
                value: newValue!=='' ? {value: newValue, type: newValueType} : undefined,
                description: newDescription,
                tags: newTags
            })
        setPage({type: PageType.DETAILS, secretName: newName})
    }, [api, newDescription, newName, newTags, newValue, newValueType, page.secretName], false)

    if(loading) return <LoadingSpinner/>
    else return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", paddingLeft: "100px", gap: "20px"}}>
            <div>
                <Typography style={{color: "green"}} as="h1" format="dtm" weight="bold">Edit Secret</Typography>
                <Divider/>
                <Typography>Leave the 'Value' field blank if you do not want to edit it or its type.</Typography>
            </div>
            <SecretInputFields
                newName={newName}
                setNewName={setNewName}
                newDescription={newDescription}
                setNewDescription={setNewDescription}
                newTags={newTags}
                setNewTags={setNewTags}
                newValue={newValue}
                setNewValue={setNewValue}
                newValueType={newValueType}
                setNewValueType={setNewValueType}
            />
            <Button
                text={"Make Changes"}
                alt={"blue"}
                waiting={updating}
                onClick={errorHandledUpdate}
            />
            <Button
                text={"Back"}
                alt={"text"}
                onClick={() => setPage({type: PageType.DETAILS, secretName: page.secretName})}
            />
        </div>
    )
}

export default SecretEditor