import {Button, DataGrid, LoadingSpinner} from "@matillion/component-library";
import TagSet from "./TagSet";
import useErrorHandledAsync from "../common/UseErrorHandledAsync";
import {useState} from "react";
import {PageState, PageType} from "./SecretManagerInterface";
import {useApi} from "@matillion/octo-react-util";
import Api from "../api/Api";

const SecretTable = ({setPage}: PageState) => {
    const api = useApi<Api>()
    const [selectedTags, setSelectedTags] = useState<string[]>([])
    const { value: secrets } = useErrorHandledAsync(() => api.listSecrets(selectedTags), [api, selectedTags])
    if(!secrets) return <LoadingSpinner/>

    const rows = secrets.map((row, index) => ({
        id: index,
        name: row.name,
        description: row.description,
        tags: {
            tags: row.tags,
            selecting: {
                selected: selectedTags,
                setSelected: setSelectedTags,
            }
        },
        edit: {
            onClick: () => setPage({type: PageType.DETAILS, secretName: row.name}),
            text: "More...",
            alt: "secondary",
        }
    }))

    return(
        <div >
            <div >
                <DataGrid
                    columns={[
                        {
                            key: 'name',
                            title: 'Name',
                        },
                        {
                            key: 'description',
                            title: 'Description',
                        },
                        {
                            key: 'tags',
                            title: 'Tags',
                            as: TagSet,
                        },
                        {
                            key: 'edit',
                            title: '',
                            as: Button,
                        }
                    ]}
                    rows={rows}
                />
            </div>
            <Button
                text={"Add new secret"}
                onClick={() => setPage({type: PageType.CREATOR})}
            />
        </div>
    )
}

export default SecretTable