import React from 'react';
import './App.css';
import SecretManagerInterface from "./components/SecretManagerInterface";
import {AppBase} from '@matillion/octo-react-util';
import Api from "./api/Api";
import {Auth0ContextInterface} from "@auth0/auth0-react";
import {appPropsLocations} from "./common/Constants";

function App() {
    return (
        <AppBase
            apiProducer={(auth0: Auth0ContextInterface, orgId: string) => new Api(auth0, orgId)}
            applicationName={"Secret Console"}
            appPropsLocations={appPropsLocations}
        >
            <SecretManagerInterface/>
        </AppBase>

    );
}

export default App;
