import React from "react";
import {Typography} from "@matillion/component-library";

interface SecretElementProps {
    name: string,
    children?: string | React.ReactNode,
}

const SecretElement = ({name, children}: SecretElementProps) => {
    const body = (typeof children === 'string') ? <Typography>{children}</Typography> : children
    return (
        <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
            <Typography as="h2" format="tl" weight="bold">{name}</Typography>
            {body}
        </div>
    )
}

export default SecretElement