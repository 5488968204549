import {Button, LoadingSpinner, Toaster, Typography} from "@matillion/component-library";
import SecretElement from "./SecretElement";
import TagSet from "./TagSet";
import {Divider} from "@mui/material";
import useSecret from "./UseSecret";
import useErrorHandledAsync from "../common/UseErrorHandledAsync";
import {PageState, PageType} from "./SecretManagerInterface";
import Api from "../api/Api";
import {useApi} from "@matillion/octo-react-util";

const SecretDetails = ({page, setPage}: PageState) => {
    if(page.type !== PageType.DETAILS) throw Error()
    const {secret} = useSecret(page.secretName)
    const api = useApi<Api>()
    const { makeToast, clearToasts } = Toaster.useToaster()

    const {errorHandledAsync: errorHandledDelete, loading: deleting} = useErrorHandledAsync(async () => {
        await api.deleteSecret(page.secretName)
        setPage({type: PageType.LIST})
    }, [api, clearToasts, makeToast, page.secretName], false)

    if(!secret) return <LoadingSpinner/>
    else return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", paddingLeft: "100px", gap: "20px"}}>
            <div>
                <Typography style={{color: "green"}} as="h1" format="dtm" weight="bold">{secret.name}</Typography>
                <Divider/>
            </div>
            <SecretElement name={"Description"}>{secret.description}</SecretElement>
            {secret.tags.length ? <SecretElement name={"Tags"}><TagSet tags={secret.tags}/></SecretElement> : null}
            <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                <Button
                    text={"Edit"}
                    alt={"blue"}
                    disabled={deleting}
                    onClick={() => setPage({type: PageType.EDITOR, secretName: page.secretName})}
                />
                <Button
                    text={"Delete"}
                    alt={"red"}
                    onClick={errorHandledDelete}
                    waiting={deleting}
                />
            </div>
            <Button
                text={"Back"}
                alt={"text"}
                onClick={() => setPage({type: PageType.LIST})}
            />
        </div>
    )
}

export default SecretDetails