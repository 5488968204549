import {DependencyList, useCallback, useEffect, useState} from "react";
import {Toaster} from "@matillion/component-library";

const useErrorHandledAsync = <T>(func: () => Promise<T>, deps: DependencyList, callOnChange: boolean = true) => {
    const [value, setValue] = useState<T>()
    const [loading, setLoading] = useState(callOnChange)
    const { makeToast, clearToasts } = Toaster.useToaster()

    const memoizedFunc = useCallback(async () => {
        setLoading(true)
        try {
            setValue(await func())
        } catch (e) {
            makeToast({
                title: 'Error',
                message: e instanceof Error ? e.message : "Error has occurred.",
                type: 'error',
                action: {
                    text: "Try Again",
                    onClick: () => {
                        memoizedFunc()
                        clearToasts()
                    }
                }
            })
        }
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearToasts, makeToast, ...deps])

    useEffect(() => {
        if(callOnChange) memoizedFunc()
    }, [callOnChange, memoizedFunc])

    return {value, loading, errorHandledAsync: memoizedFunc}
}

export default useErrorHandledAsync