import {useState} from "react";
import {ValueType} from "../interfaces/Secret";
import {Button, Typography} from "@matillion/component-library";
import {Divider} from "@mui/material";
import SecretInputFields from "./SecretInputFields";
import useErrorHandledAsync from "../common/UseErrorHandledAsync";
import {PageState, PageType} from "./SecretManagerInterface";
import {useApi} from "@matillion/octo-react-util";
import Api from "../api/Api";

const SecretCreator = ({setPage}: PageState) => {
    const api = useApi<Api>()

    const [newName, setNewName] = useState('')
    const [newDescription, setNewDescription] = useState('')
    const [newTags, setNewTags] = useState<string[]>([])
    const [newValue, setNewValue] = useState('')
    const [newValueType, setNewValueType] = useState<ValueType>(ValueType.RAW)

    const {errorHandledAsync: errorHandledCreate, loading: creating} = useErrorHandledAsync(async () => {
        await api.createSecret(newName, {
            name: newName,
            value: {value: newValue, type: newValueType},
            description: newDescription,
            tags: newTags
        })
    }, [api, newDescription, newName, newTags, newValue, newValueType], false)

    const createSecret = async () => {await errorHandledCreate(); setPage({type: PageType.DETAILS, secretName: newName})}

    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", paddingLeft: "100px", gap: "20px"}}>
            <div>
                <Typography style={{color: "green"}} as="h1" format="dtm" weight="bold">Create Secret</Typography>
                <Divider/>
            </div>
            <SecretInputFields
                newName={newName}
                setNewName={setNewName}
                newDescription={newDescription}
                setNewDescription={setNewDescription}
                newTags={newTags}
                setNewTags={setNewTags}
                newValue={newValue}
                setNewValue={setNewValue}
                newValueType={newValueType}
                setNewValueType={setNewValueType}
            />
            <Button
                text={"Create"}
                alt={"blue"}
                waiting={creating}
                disabled={newName===''||newDescription===''||newValue===''}
                onClick={createSecret}
            />
            <Button
                text={"Back"}
                alt={"text"}
                onClick={() => setPage({type: PageType.LIST})}
            />
        </div>
    )
}

export default SecretCreator