import {useState} from "react";
import {CensoredSecret} from "../interfaces/Secret";
import useErrorHandledAsync from "../common/UseErrorHandledAsync";
import Api from "../api/Api";
import {useApi} from "@matillion/octo-react-util";

const useSecret = (secretName: string) => {
    const [secret, setSecret] = useState<CensoredSecret>()
    const api = useApi<Api>()

    const {loading} = useErrorHandledAsync(async () => {
            setSecret(await api.getCensoredSecret(secretName))
    }, [api, secretName])

    return {secret, loading}
}

export default useSecret