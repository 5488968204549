export class CustomApiError extends Error {
    constructor(message: string, errorType: CustomErrorType, data: any) {
        super(message)
        this.errorType = errorType
        this.data = data
    }
    errorType: CustomErrorType
    data: any
}

export enum CustomErrorType {
    INCONSISTENT_SECRET_NAME,
    SECRET_MISSING_FIELDS,
    SECRET_NOT_FOUND
}