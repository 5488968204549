import {Badge, Tooltip} from "@matillion/component-library";
import {useAppProps} from "@matillion/octo-react-util";
import SpecialTag from "../interfaces/SpecialTag";

interface TagSetProps {
    tags: string[]
    editing?: {
        setTags: (tags: string[]) => void
    }
    selecting?: {
        selected: string[],
        setSelected: (selected: string[]) => void
    }
}

const TagSet = ({tags, editing, selecting}: TagSetProps) => {
    if(editing && selecting) throw Error("Cannot edit and select tags at the same time.")

    const {secretService: {specialTags}} = useAppProps()

    function onClick(tag: string) {
        if(editing) editing.setTags(tags.filter((t) => t !== tag ))
        else if(selecting) {
            const {selected, setSelected} = selecting
            if(selected.includes(tag)) setSelected(selected.filter((t) => t !== tag ))
            else setSelected([...selected, tag])
        }
    }

    return (
        <div style={{display: "flex", gap: "10px", flexWrap: "wrap"}}>
            {
                tags.map((tag, index) => {
                    const specialTag = specialTags.find((specialTag: SpecialTag) => specialTag.name === tag)
                    const badge = (
                        <Badge
                            colour={specialTag ? "green" : undefined}
                            theme={selecting?.selected.includes(tag) ? "filled" : "outlined"}
                            onClick={() => onClick(tag)}
                            key={index}
                        >{tag}</Badge>
                    )
                    return specialTag ? <Tooltip key={index} content={specialTag.description}>{badge}</Tooltip> : badge
                })
            }
        </div>
    )
}

export default TagSet