import SecretDetails from "./SecretDetails";
import SecretEditor from "./SecretEditor";
import SecretCreator from "./SecretCreator";
import SecretsList from "./SecretsList";
import React, {useState} from "react";

export enum PageType {
    LIST,
    DETAILS,
    EDITOR,
    CREATOR,
}

type Page =
    { type: PageType.LIST }
    | { type: PageType.DETAILS, secretName: string }
    | { type: PageType.EDITOR, secretName: string }
    | { type: PageType.CREATOR }

export interface PageState {
    page: Page,
    setPage: (page: Page) => void
}

const SecretManagerInterface = () => {
    const [page, setPage] = useState<Page>({ type: PageType.LIST });
    const pageState = {page, setPage}

    const getPage = (): React.ReactNode => {
        switch (page.type) {
            case PageType.LIST: return <SecretsList {...pageState}/>
            case PageType.DETAILS: return <SecretDetails {...pageState}/>
            case PageType.EDITOR: return <SecretEditor {...pageState}/>
            case PageType.CREATOR: return <SecretCreator {...pageState}/>
        }
    }

    return (
        <div style={{padding: "20px"}}>
            {getPage()}
        </div>
    )
}

export default SecretManagerInterface