import SecretTable from "./SecretTable";
import {Badge, Typography} from "@matillion/component-library";
import {Divider} from "@mui/material";
import {PageState} from "./SecretManagerInterface";

const SecretsList = (pageState: PageState) => {
    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "20px"}}>
            <div>
                <Typography style={{color: "green"}} as="h1" format="dtm" weight="bold">Secrets</Typography>
                <Divider/>
            </div>
            <Typography>Click on a <Badge>Tag</Badge> to filter the list.</Typography>
            <SecretTable {...pageState}/>
        </div>
    )
}

export default SecretsList